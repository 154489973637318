import React from "react";
import "./Base.css";

export default function Base() {
  return (
    <>
      <div className="bg-blend"></div>
      
    </>
  );
}
